@import 'variables';

.button-list-group {
    justify-content: center;

    ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 1rem;

        li {
            button {
                border: none;
                padding: 1rem;
                cursor: pointer;
                background-color: var(--secondary);
                color: var(--white);
                transition: all 300ms;

                &:hover {
                    background-color: var(--secondary);
                }
    
                &:disabled {
                    background-color: var(--secondary);
                    cursor: default;
                }
            }
        }
    }
}

.button-group {
    display: flex;
    flex: 1;
    justify-content: center;

    .btn {
        padding: 0.5rem;

        button {
            width: 100%;
            background-color: var(--grey-pattern);
            border: 0px solid #000;
            color: var(--black);
            padding: .5rem;
            border-radius: .9rem;
            cursor: pointer;
            transition: all .3s;
            display: flex;
            justify-content: center;
            font-size: 0.9rem;

            &:hover {
                background-color: var(--secondary);
                color: var(--white);
                filter: brightness(0.9);
            }

            &:disabled {
                background-color: var(--grey-dark);
                opacity: 0.1;
                color: var(--white);
                cursor: default;
            }

            &:focus {
                outline-color: #ffff;
                outline-width: 0;
            }
        }

        .btn-big {
            padding: 0.5rem 3rem;
            background-color: var(--secondary);
            color: var(--white);

            &:disabled {
                background-color: var(--grey-dark);
            }
        }

        .selected {
            background-color: var(--secondary);
            color: var(--white);
        }
    }

    
}

.button-small-group {
    display: flex;
    margin: 0 -1rem;

    .btn {
        width: 100%;
        padding: 0 1rem;

        button {
            width: 100%;
            background-color: var(--secondary);
            border: 0;
            color: var(--white);
            padding: 0 0.5rem;
            border-radius: var(--border-radius-medium);
            cursor: pointer;
            transition: all 300ms;
            display: flex;
            justify-content: center;
            font-size: 80%;

            &:hover {
                background-color: rgba(var(--secondary), 0.8);
            }

            &:disabled {
                background-color: rgba(var(--secondary), 0.5);
                cursor: default;
            }
        }
    }
}

.btn-block {
    display: block;
    background-color: var(--secondary);
    border: 0;
    color: var(--white);
    padding: 0.5rem;
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    transition: all 300ms;



    &:hover {
        background-color: rgba(var(--secondary), 0.8);
        text-decoration: none;
    }

    &:disabled {
        background-color: rgba(var(--secondary), 0.5);
        color: var(--white) !important;
        cursor: default;
    }
}