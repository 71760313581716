// COLORS
:root {
    // EXAMPLE:
    --primary: #6C6D70;
    --primary-rgb: 28, 72, 102;
    
    --secondary: #CFCFCF;
    --secondary-rgb: 108, 109, 112;

    --alert: #FFD83A;
    --alert-rgb: 255, 216, 58;

    --warning: #F2453E;
    --warning-rgb: 242, 69, 62;

    --success: #59BE60;
    --success-rgb: 89, 190, 96;

    --info: #154768;
    --info-rgb: 21, 71, 104;

    --grey-lighter: #F4F4F4; 
    --grey-lighter-rgb: 211, 211, 213; 

    --grey-light: #EFEFEF;
    --grey-light-rgb: 159, 160, 163; 

    --grey-pattern: #CFCFCF;
    --grey-pattern-rgb: 28, 72, 102; 

    --grey-dark: #6C6D70;
    --grey-dark-rgb: 82, 83, 86; 

    --grey-darker: #000000;
    --grey-darker-rgb: 32, 33, 34;

    --white: #FFFFFF;
    --white-rgb: 255, 255, 255;

    --black: #000000;
    --black-rgb: 0, 0, 0;

    --background: #FBFBFB;

    --desk-margin: 1rem;
    --desk-padding: 1rem;
    --mob-margin: 0.5rem;
    --mob-padding: 0.5rem;
    --desk-padding-top: 1rem;
    --mob-padding-top: 1rem;

// TEXTS
    --basic-text-font-size: 0.7rem;

// border radius
    --border-radius-small: 0.5rem;
    --border-radius-medium: 0.3rem;
  }



