@import 'variables';

.form-view {
    display: flex;
    flex: 1;
    flex-direction: column;

    .form-header {
        background-color: var(--primary);
        padding: 0.3rem 0.3rem;
        margin-bottom: 1rem;

        * {
            text-align: center;
            margin: 0;
            color: var(--white);
        }
    }

    .form-footer {
        * {
            text-align: center;
        }
    }

    .form-item {

        .radio-group {
            margin: 0 -1rem;

            .example-radio-button {
                padding: 0 1rem;
            }
        }

        .form-item-full {
            width: 100%;
        }

        .error {
            background-color: var(--warning);
            color: #fff;
            font-size: 0.7rem;
            padding: 0.1rem;
        }

        .tip {
            margin-bottom: 0.5rem;
            font-size: .79rem;
        }
    }

    .card-brand-logo {
        display: flex;

        img {
            width: 2.5rem;
            align-self: end;
        }
    }
}

/deep/ {
    .mat-form-field {
        .mat-form-field-infix {
            border-top: .5rem;
        }
    }

    .mat-radio-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        .mat-radio-container {
            width: 15px;
            height: 15px;

            .mat-radio-outer-circle {
                width: 10px;
                height: 10px;
            }

            .mat-radio-inner-circle {
                width: 10px;
                height: 10px;
            }
        }

        .mat-radio-label-content {
            padding-left: 1px;
        }

        .mat-radio-button {
            transition: all 300ms;
            

            .mat-radio-label {
                width: 100%;
                margin: 0;
                padding: 0.3rem;
                //margin-bottom: 0.3rem;

            }
        }

        .mat-radio-button:nth-child(even) {
            background-color: var(--grey-light);
        }

        .mat-radio-checked {
            background-color: var(--info) !important;
        }

        .mat-radio-checked {
            .mat-radio-outer-circle {
                background-color: var(--secondary);
            }

            .mat-radio-inline-circle {
                border: 1px solid var(--secondary);
            }
        }

        .mat-radio-checked {
            .mat-radio-label-content {
              color: var(--white);
            }
          }
    }

}
