@import 'variables';

// container
.container {
    display: block;
    margin: auto;
    padding: 0 1rem
}

.laptop-l {
    max-width: 1440px;
}

.laptop {
    max-width: 1024px;
}

.tablet {
    max-width: 768px;
}

.mobile-l {
    max-width: 425px;
}

.mobile-m {
    max-width: 375px;
}

.mobile-s {
    max-width: 320px;
}

.cel {
    display: flex;
    flex: 1;
}

.row {
    display: flex;
    flex: 1;
    margin: 0 -0.5rem;

    .cel {
        padding: 0 0.5rem;
        flex: 1;
    }
}

.col {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 -0.5rem;

    .cel {
        padding: 0 0.5rem;
    }
}



.pannel {
    display: flex;
    background-color: var(--background);
    flex: 1;
    padding: 1rem;
    border-radius: var(--border-radius-medium);
}

.lateral-pannel {
    display: flex;
    background-color: var(--background);
    flex: 1;
    padding: 1rem;
}

.content-header {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    margin-bottom: 1rem;

    * {
        text-align: center;
    }
}

.content-footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 1rem;
    margin-top: 1rem;
}

.fluid {
    display: flex;
    flex: 1;
  }

  .warning {
      display: flex;
      padding: 1rem;
      background: var(--yellow);
      margin-bottom: 1rem;

      * {
          margin: 0;
      }
  }

  .form-box {
    border: 1px solid #cfcfcf;
    background-color: #fff;
    padding: .5rem;
    flex: 1;
    margin: 0.5rem .3rem;
  }

  // card
  .card {
    display: flex;
    flex-direction: column;
    background-color: var(--background);
    margin-bottom: 1rem;
    padding: 1rem;
    flex: 1;

    header {
        flex: 0;

        * {
            text-align: center;
        }
    }

    .body {
        flex: 1;
        display: flex;

        .text {
            flex: 1;

            ul {
                list-style: none;
                padding: 0;
            }
        }

        .button {
            flex: 0;
        }
    }
  }
  