@import 'variables';


.text-center {
    text-align: center;
}

.simple-list {
    list-style: none;
    padding: 0;
}

.simple-text {
    flex-direction: column;
}

.list-default {
    list-style: none;
    padding: 0;

    li {
        display: flex;
        justify-content: space-between;
        padding: 0.3rem;
        color: var(--grey-dark);
    }

    li:nth-child(odd) {
        background: var(--grey-light);

    }
}